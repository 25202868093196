.custom-event {
  position: relative;
  cursor: pointer;
}

.custom-event:hover::after {
  content: attr(title);
  position: absolute;
  z-index: 100;
  bottom: 25px; /* Position the tooltip below the event */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 4px;
  /* max-width: 300px; Adjust as needed */
  white-space: normal;
  text-align: left;
  word-wrap: break-word;
  font-size: 12px;
}
