@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.login {
  margin: 0 !important; /* Forces margin to be 0, overriding other styles */
  height: 100vh;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  overflow-y: auto;
}
/* Target content-wrapper only when it's inside a parent with the login class */
.login .content-wrapper {
  margin-right: 0;
  margin-left: 0;
}

.app .sidebar {
  position: fixed;
  width: 50px; /* Update this with your actual sidebar width */
  height: 100vh;
  overflow-y: auto;
}


.app .content-wrapper {
  /*margin-left:  50px;  Same as the sidebar width */
  /*width: calc(100% - 240px);  width of content-wrapper should be the remaining width after subtracting the sidebar width */
  box-sizing: border-box; 
  margin-right: 3px; 
  margin-left: 3px;  
  height: 100vh;
  width: calc(100% - 205px); /*side bar is always 200 px for some reason.. need to see where it is being set and change it to a percentage of the screen size instead. also added an extra 5px buffer*/
}

.app .content-wrapper .topbar {
  position: fixed;
  top: 0;
  /*width: calc(100% -  50px);  width of topbar should be same as the width of content-wrapper */
  box-sizing: border-box; 
  margin-right: 3px; 
  margin-left: 3px; 
  width: 100%;
  z-index: 100;
  height: 100vh;
}

.app .content-wrapper .content {
  /* margin-top: 5px;  */
  overflow-y: auto;
  height: calc(100vh - 45px ); /* Adjust as necessary */
  width: 100%;
}

.header-content {
  /* Style for the header section */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5; /* Example background color */
  border-bottom: 1px solid #e0e0e0; /* Example bottom border */
}

.grid-content {
  /* Style for the grid container */
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.app {
  display: flex;
  position: relative;
  padding-bottom: env(safe-area-inset-bottom);

}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fc-popover {
  max-height: 150px; 
  overflow-y: auto; /* Enables vertical scrolling */
}

.day-number {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 2;
}

.fc {
  height: 17vh; /* height for Dashboard calendart */
}


@media screen and (display-mode: standalone){
  .topbar {
    padding-top: env(safe-area-inset-top);
  }

  .sidebar{
    padding-top: env(safe-area-inset-top);
   }

  .app {
      min-height: -webkit-fill-available; /* For iOS */
      padding-bottom: env(safe-area-inset-bottom); /* Adjusts for the bottom safe area */
      min-height: var(--app-height);
      overflow-y: auto;

      
  }
  .app .content-wrapper .content {
    height: calc(100vh ); /* Adjust height for mobile */
    overflow-y: auto;

  }
  
  
}


/* Media query for mobile screens */
@media (max-width: 600px) {
  .fc {
    height: 40vh; /* Increased height for mobile screens */
  }
}


/* Mobile Styles */
/* Tablet view*/
@media screen and (max-width: 768px) {
  .app .sidebar {
    width: 100%; /* Full-width sidebar for mobile */
    height: auto; /* Auto height for mobile */
  }

  .app .content-wrapper {
    margin-left: 0; /* No sidebar margin for mobile */
    width: 100%; /* Full width for content on mobile */
  }

  .app .content-wrapper .topbar {
    width: 100%; /* Full-width topbar for mobile */
  }

  .app .content-wrapper .content {
    height: calc(100vh ); /* Adjust height for mobile */
  }

  .grid-content {
    /* Adjust grid container layout for tablet and mobile view */
    padding: 10px;
  }

  .header-content {
    /* Adjust header layout for tablet and mobile view */
    flex-direction: column;
    align-items: flex-start;
  }
}


/* Styles for large smartphones (screens smaller than 480px) */
@media screen and (max-width: 480px) {
  .app .sidebar {
    /* Example: Change to a collapsible sidebar for smaller screens */
    width: 100%; /* Full-width sidebar for small screens */
    /*height: auto;  Auto height for better mobile compatibility */
  }

  .app .content-wrapper {
    box-sizing: border-box;
    margin-right: 3px; 
    margin-left: 3px; 
    width: 100%; /* Full width for content area */
    height: calc(50vh -10px);
  }

  .app .content-wrapper .topbar {
    box-sizing: border-box;
    margin-right: 3px; 
    margin-left: 3px; 
    position: fixed;
    width: 100%; /* Full width for top bar */
    z-index: 100;
    height: 88px
  }

  .app .content-wrapper .content {
    /* margin-top: 5px;  */
    overflow-y: auto;
    height: calc(90vh - 60px); /*Adjust as necessary

    width: 100%;  
  }

  .grid-content {
    /* Further adjustments for mobile view if needed */
  }

  .header-content {
    /* Adjustments for mobile header layout */
    padding: 10px;
  }

}



