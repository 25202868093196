/* responsive.css */

@media screen and (max-width: 480px) {
    .responsive-text {
      font-size: 14px;
    }
  
    .responsive-grid {
      padding: 5px;
    }
  
    /* Add more responsive styles as needed */
  }
  