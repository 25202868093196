.active_header_cell {
    background-color: #0B1537 !important;
    color: white !important;
    font-weight: bold;
}

.active_header_cell>svg {
    color: #eee !important;
}

.remove-border {
    border: none;
    outline: none;
    /* padding: 0; */
}

.sub_total_field {
    margin: 5px 0 !important;
}

.sub_total_field>div>input {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.horizontal_line {
    padding-top: 11px !important;
}

.break_word {
    word-break: break-all;
}

.grid_invoice_head {
    display: grid !important;
}

.grid_invoice_subhead {
    display: none !important;
}

.invoice_logo_fit {
    object-fit: contain !important;
    width: 50% !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    /* margin: 0; */
}

/* .res_table_head {
    
} */

.pdf_amount_due > div > input {
    margin-top: 10px;
}

.borderless_input>div>fieldset {
    border: none !important;
}

.res_table_row>td>label {
    display: none;
}

.borderless_input>div>input {
    padding: 10px 0;
}

.res_mobile_create_edit {
    display: none;
}

.res_mobile_add_line {
    margin-left: 15px !important;
}

@media screen and (max-width: 500px) {
    .grid_invoice_head {
        display: none !important;
    }

    .grid_invoice_subhead {
        display: grid !important;
    }

    .grid_invoice_logo {
        margin: auto;
    }

    .invoice_title_name {
        margin-bottom: 10px !important;
    }

    .invoice_logo_fit {
        width: 100% !important;
    }

    .res_table_head {
        display: none !important;
    }

    .res_table_row {
        display: flex !important;
        flex-direction: column !important;
        border-bottom: 0.5px solid #999;
        border-bottom-style: double;
    }

    .res_table_row>td {
        display: flex !important;
        align-items: center !important;
    }

    .res_table_row>td>label {
        width: 35%;
        display: block !important;
        font-size: 15px;
        color: #666;
    }

    .res_table_row>td>.borderless_input {
        width: 65% !important;
    }

    .res_table_row>td>.borderless_input>div>input {
        text-align: end;
        padding: 5px;
    }

    .res_line_total > label {
        font-weight: 600;
    }

    .res_line_total > .borderless_input > div > input {
        font-weight: 600;
    }

    .res_table_row>td>.res_mobile_create_input {
        width: 65% !important;
    }

    .res_web_create_edit {
        display: none;
    }

    .res_mobile_create_edit {
        display: block !important;
    }

    .res_mobile_add_line {
        margin-top: 10px !important;
        margin-left: 0 !important;
    }

    .res_invoice_header_titles {
        flex: unset !important;
        width: 100%;
    }

    .res_invoice_header_titles > .res_invoice_head_date {
        width: 100%;
    }
}

/* invoice.module.css */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .res_invoice_head_date {
      min-width: 120px !important;
    }
  }